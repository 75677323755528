<template>
  <div class="GatherPraise_wrapper">
    <el-form :model="CollectDetail" :rules="GatherPraiseRules" label-width="130px">
      <el-form-item label="集赞活动名称" prop="name">
        <el-input
          v-model="CollectDetail.name"
          placeholder="请输入集赞活动名称"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="活动规则" prop="rule">
        <el-input
          v-model="CollectDetail.rule"
          placeholder="请输入活动规则 "
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="集赞奖品图" required>
        <div>
          <label for="brand-logo" class="el-upload el-upload--picture-card">
            <input
              type="file"
              ref="brandlogo"
              hidden
              id="brand-logo"
              data-type="brandLogo"
              name="upload"
              accept="image/*"
              @change="uploadImage"
            />
            <img
              v-if="CollectDetail.img"
              :src="CollectDetail.img"
              style="width:142px;height:143px;"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </label>
        </div>
        <div class="ml10 form_tips">建议尺寸710x200 px，支持JPG，PNG，JPEG格式</div>
      </el-form-item>

      <el-form-item label="奖品名称" prop="prizeName">
        <el-input
          v-model="CollectDetail.prizeName"
          placeholder="请输入奖品名称"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="奖品库存" prop="stock">
        <el-input
          v-model="CollectDetail.stock"
          placeholder="请输入奖品库存"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="集赞规则">
        <div>用户参与集赞是否需要报名</div>
        <el-radio-group v-model="CollectDetail.enrollmentFlag">
          <el-radio label="true">需报名</el-radio>
          <el-radio label="false">无需报名</el-radio>
        </el-radio-group>
        <div>好友助力集赞</div>
        <el-radio-group v-model="CollectDetail.helpFlag">
          <el-radio label="more">可多次助力</el-radio>
          <el-radio label="one">仅可助力一次</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="集赞所需人数" prop="num">
        <el-input
          v-model="CollectDetail.num"
          placeholder="请输入集赞所需人数"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="数据优化">
        <div>虚拟参与集赞人数（实际集赞人数 = 虚拟参与集赞人数 + 实际参与集赞人数）</div>
        <el-input
          v-model="CollectDetail.virtualNum"
          placeholder="请输入虚拟参与集赞人数"
          maxlength="10"
          show-word-limit
        ></el-input>
        <div>剩余份数（剩余份数会随实际库存减少而减少，最少为0 ）</div>
        <el-input
          v-model="CollectDetail.virtualStock"
          placeholder="请输入剩余份数"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitCollectLike">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";
export default {
  name: "GatherPraise", // 活动-营销-集赞设置
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      GatherPraiseRules: {
        name: [
          { required: true, message: "请输入集赞活动名称", trigger: "blur" },
        ],
        rule: [{ required: true, message: "请输入活动规则", trigger: "blur" }],
        prizeName: [
          { required: true, message: "请输入奖品名称", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请输入奖品库存", trigger: "blur" }],
        num: [
          { required: true, message: "请输入集赞所需人数", trigger: "blur" },
        ],
      },
      CollectDetail: {
        actId: 0,
        enrollmentFlag: "false",
        helpFlag: "one",
        img: "",
        name: "",
        num: "",
        prizeName: "",
        rule: "",
        stock: "",
        virtualNum: "",
        virtualStock: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.CollectDetail.img = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},
    // 集赞设置-查看，传入活动id(尚未创建时返回null）
    async getCollectLike() {
      try {
        const data = await MarketingAjax.getCollectLike(this.activityID);
        if (data.data) {
          this.CollectDetail = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 确认新建或编辑集赞设置
    async submitCollectLike() {
      this.CollectDetail.actId = this.activityID;
      let act = JSON.parse(JSON.stringify(this.CollectDetail));
      // 验证数据
      if (this.isNullOrEmpty(act.name)) {
        this.$message.warning("集赞活动名称不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.rule)) {
        this.$message.warning("活动规则不能为空");
        return;
      }
      if (!act.img) {
        this.$message.warning("集赞奖品图不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.prizeName)) {
        this.$message.warning("奖品名称不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.stock)) {
        this.$message.warning("奖品库存不能为空");
        return;
      }

      if (!/(^[1-9]\d*$)/.test(act.stock)) {
        this.$message.warning("奖品库存需为大于0的整数");
        return;
      }
      if (act.stock > 99999999 || act.stock <= 0) {
        this.$message.warning("奖品库存范围为1~99999999");
        return;
      }
      if (this.isNullOrEmpty(act.num)) {
        this.$message.warning("集赞所需人数不能为空");
        return;
      }
      if (!/(^[1-9]\d*$)/.test(act.num)) {
        this.$message.warning("集赞所需人数需为整数且大于0");
        return;
      }
      console.log(act.num);
      if (act.num > 99999999 || act.num <= 0) {
        this.$message.warning("集赞所需人数范围为0~99999999");
        return;
      }
      if (act.virtualNum || act.virtualNum == 0) {
        if (!/(^[1-9]\d*$)/.test(act.virtualNum)) {
          if (act.virtualNum == 0) {
          } else {
            this.$message.warning("虚拟参与集赞人数需为整数且大于等于0");
            return;
          }
        }
      }
      if (act.virtualNum > 99999999 || act.virtualNum < 0) {
        this.$message.warning("虚拟参与集赞人数范围为0~99999999");
        return;
      }
      if (act.virtualStock || act.virtualStock == 0) {
        if (!/(^[1-9]\d*$)/.test(act.virtualStock)) {
          if (act.virtualStock == 0) {
          } else {
            this.$message.warning("剩余份数需为整数且大于等于0");
            return;
          }
        }
      }
      if (act.virtualStock > 99999999 || act.virtualStock < 0) {
        this.$message.warning("虚拟参与集赞人数范围为0~99999999");
        return;
      }
      console.log(act);
      await MarketingAjax.saveCollectLike(act);
      this.$message.success("编辑集赞设置成功");
      await this.getCollectLike();
    },
  },
  created() {
    this.getCollectLike();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>
<style lang="scss">
.GatherPraise_wrapper {
  .el-upload--text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 178px;
    height: 178px;
  }
  .form_tips {
    color: #888;
    font-size: 12px;
  }
}
</style>
