var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "GatherPraise_wrapper" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.CollectDetail,
            rules: _vm.GatherPraiseRules,
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "集赞活动名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入集赞活动名称",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.name,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "name", $$v)
                  },
                  expression: "CollectDetail.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动规则", prop: "rule" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入活动规则 ",
                  maxlength: "50",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.rule,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "rule", $$v)
                  },
                  expression: "CollectDetail.rule"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "集赞奖品图", required: "" } }, [
            _c("div", [
              _c(
                "label",
                {
                  staticClass: "el-upload el-upload--picture-card",
                  attrs: { for: "brand-logo" }
                },
                [
                  _c("input", {
                    ref: "brandlogo",
                    attrs: {
                      type: "file",
                      hidden: "",
                      id: "brand-logo",
                      "data-type": "brandLogo",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadImage }
                  }),
                  _vm.CollectDetail.img
                    ? _c("img", {
                        staticStyle: { width: "142px", height: "143px" },
                        attrs: { src: _vm.CollectDetail.img }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              )
            ]),
            _c("div", { staticClass: "ml10 form_tips" }, [
              _vm._v("建议尺寸710x200 px，支持JPG，PNG，JPEG格式")
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "奖品名称", prop: "prizeName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入奖品名称",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.prizeName,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "prizeName", $$v)
                  },
                  expression: "CollectDetail.prizeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖品库存", prop: "stock" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入奖品库存",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.stock,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "stock", $$v)
                  },
                  expression: "CollectDetail.stock"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "集赞规则" } },
            [
              _c("div", [_vm._v("用户参与集赞是否需要报名")]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.CollectDetail.enrollmentFlag,
                    callback: function($$v) {
                      _vm.$set(_vm.CollectDetail, "enrollmentFlag", $$v)
                    },
                    expression: "CollectDetail.enrollmentFlag"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "true" } }, [
                    _vm._v("需报名")
                  ]),
                  _c("el-radio", { attrs: { label: "false" } }, [
                    _vm._v("无需报名")
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("好友助力集赞")]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.CollectDetail.helpFlag,
                    callback: function($$v) {
                      _vm.$set(_vm.CollectDetail, "helpFlag", $$v)
                    },
                    expression: "CollectDetail.helpFlag"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "more" } }, [
                    _vm._v("可多次助力")
                  ]),
                  _c("el-radio", { attrs: { label: "one" } }, [
                    _vm._v("仅可助力一次")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "集赞所需人数", prop: "num" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入集赞所需人数",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.num,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "num", $$v)
                  },
                  expression: "CollectDetail.num"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "数据优化" } },
            [
              _c("div", [
                _vm._v(
                  "虚拟参与集赞人数（实际集赞人数 = 虚拟参与集赞人数 + 实际参与集赞人数）"
                )
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入虚拟参与集赞人数",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.virtualNum,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "virtualNum", $$v)
                  },
                  expression: "CollectDetail.virtualNum"
                }
              }),
              _c("div", [
                _vm._v("剩余份数（剩余份数会随实际库存减少而减少，最少为0 ）")
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入剩余份数",
                  maxlength: "10",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.CollectDetail.virtualStock,
                  callback: function($$v) {
                    _vm.$set(_vm.CollectDetail, "virtualStock", $$v)
                  },
                  expression: "CollectDetail.virtualStock"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitCollectLike }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }